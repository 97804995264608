import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import qs from 'qs'
import _ from 'underscore';
import global_ from "@/components/Global"; //引用模块进来
import Meta from 'vue-meta'
// import echarts from "echarts";
import * as echarts from 'echarts'

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(ElementUI);
Vue.use(VueQuillEditor)

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;    //全局注册
Vue.prototype.$qs = qs;    //全局注册
Vue.prototype.$underscore = _;
Vue.prototype.GLOBAL = global_//挂载到Vue实例上面
Vue.prototype.$echarts = echarts;
Vue.use(Meta)

axios.defaults.baseURL='/api';

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



