import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Login',
        component: () =>
            import ('../views/Login'),
        meta: { title: "问卷调查管理系统" },
    },
    {
        path: '/home',
        name: 'Home',
        component: () =>
            import ('../views/Home'),
        meta: { title: "问卷调查管理系统" },
        children: [{
                path: '/staff/list',
                name: 'staff_list',
                component: () =>
                    import ('../views/staff/list'),
                // meta:{keepAlive:true},
            },
            {
                path: '/staff/detail',
                name: 'staff_detail',
                component: () =>
                    import ('../views/staff/detail'),
                // meta:{keepAlive:true},
            },
            {
                path: '/admin/list',
                name: 'admin_list',
                component: () =>
                    import ('../views/admin/list'),
                // meta:{keepAlive:true},
            },
            {
                path: '/admin/detail',
                name: 'admin_detail',
                component: () =>
                    import ('../views/admin/detail'),
                // meta:{keepAlive:true},
            },
            {
                path: '/option_group/list',
                name: 'option_group_list',
                component: () =>
                    import ('../views/option_group/list'),
                // meta:{keepAlive:true},
            },
            {
                path: '/option_group/detail',
                name: 'option_group_detail',
                component: () =>
                    import ('../views/option_group/detail'),
                // meta:{keepAlive:true},
            },
            {
                path: '/survey/list',
                name: "survey_list",
                component: () =>
                    import ('../views/survey/list'),
                // meta:{keepAlive:true},
            },
            {
                path: '/survey/detail',
                name: "survey_detail",
                component: () =>
                    import ('../views/survey/detail'),
                // meta:{keepAlive:true},
            },
            {
                path: '/patrol_type/list',
                name: "patrol_type_list",
                component: () =>
                    import ('../views/patrol_type/list'),
                // meta:{keepAlive:true},
            },
            {
                path: '/patrol_survey/list',
                name: "patrol_survey_list",
                component: () =>
                    import ('../views/patrol_survey/list'),
                // meta:{keepAlive:true},
            },
            {
                path: '/patrol_survey/question_bank',
                name: "patrol_survey_question_bank",
                component: () =>
                    import ('../views/patrol_survey/question_bank'),
                // meta:{keepAlive:true},
            },
            {
                path: '/patrol_survey/reconsider_list',
                name: "patrol_survey_reconsider_list",
                component: () =>
                    import ('../views/patrol_survey/reconsider_list'),
                // meta:{keepAlive:true},
            },
            {
                path: '/patrol_survey/reconsider_detail',
                name: "patrol_survey_reconsider_detail",
                component: () =>
                    import ('../views/patrol_survey/reconsider_detail'),
                // meta:{keepAlive:true},
            },
            {
                path: '/patrol_survey/problem',
                name: "patrol_survey_problem",
                component: () =>
                    import ('../views/patrol_survey/problem'),
                // meta:{keepAlive:true},
            },
            {
                path: '/patrol_survey/problem_list',
                name: "patrol_survey_problem_list",
                component: () =>
                    import ('../views/patrol_survey/problem_list'),
                // meta:{keepAlive:true},
            },
            {
                path: '/patrol_survey_submit/list',
                name: "patrol_survey_submit_list",
                component: () =>
                    import ('../views/patrol_survey_submit/list'),
                // meta:{keepAlive:true},
            },
            {
                path: '/patrol_survey_submit/submitHistoryList',
                name: "SubmitHistoryList",
                component: () =>
                    import ('../views/patrol_survey_submit/submitHistoryList'),
                // meta:{keepAlive:true},
            },
            {
                path: '/patrol_survey_submit/submitHistoryDetail',
                name: "SubmitHistoryDetail",
                component: () =>
                    import ('../views/patrol_survey_submit/submitHistoryDetail'),
                // meta:{keepAlive:true},
            },
            {
                path: '/community/list',
                name: "community_list",
                component: () =>
                    import ('../views/community/list'),
                // meta:{keepAlive:true},
            },
            {
                path: '/project/list',
                name: 'ProjectList',
                component: () =>
                    import ('../views/project/list'),
                // meta:{keepAlive:true},
            },
            {
                path: '/project/detail',
                name: 'ProjectDetail',
                component: () =>
                    import ('../views/project/detail'),
                // meta:{keepAlive:true},
            },
            {
                path: '/project/satisfaction',
                name: 'ProjectSatisfaction',
                component: () =>
                    import ('../views/project/satisfaction'),
                // meta:{keepAlive:true},
            },
            {
                path: '/project/satisfaction_bank',
                name: 'project_satisfaction_bank',
                component: () =>
                    import ('../views/project/satisfaction_bank'),
                // meta:{keepAlive:true},
            },
            {
                path: '/project/satisfaction_question',
                name: 'project_satisfaction_question',
                component: () =>
                    import ('../views/project/satisfaction_question'),
                // meta:{keepAlive:true},
            },
            {
                path: '/project/question',
                name: 'ProjectQuestion',
                component: () =>
                    import ('../views/project/question'),
                // meta:{keepAlive:true},
            },
            {
                path: '/province/list',
                name: 'ProvinceList',
                component: () =>
                    import ('../views/province/list'),
                // meta:{keepAlive:true},
            },
            {
                path: '/province/groupList',
                name: 'ProvinceGroupList',
                component: () =>
                    import ('../views/province/groupList'),
                // meta:{keepAlive:true},
            },
            {
                path: '/province/groupDetail',
                name: 'ProvinceGroupDetail',
                component: () =>
                    import ('../views/province/groupDetail'),
                // meta:{keepAlive:true},
            },
            {
                path: '/report/projectList',
                name: 'ReportProjectList',
                component: () =>
                    import ('../views/report/projectList'),
                meta: { keepAlive: true },
            },
            {
                path: '/report/patrolSurveyList',
                name: 'ReportpatrolSurveyList',
                component: () =>
                    import ('../views/report/patrolSurveyList'),
                meta: { keepAlive: true },
            },
            {
                path: '/report/patrolSurveyDetail',
                name: 'ReportpatrolSurveyDetail',
                component: () =>
                    import ('../views/report/patrolSurveyDetail'),
                meta: { keepAlive: true },
            },
            {
                path: '/company/list',
                name: 'CompanyList',
                component: () =>
                    import ('../views/company/list'),
                meta: { keepAlive: true },
            },
            {
                path: '/company/detail',
                name: 'CompanyDetail',
                component: () =>
                    import ('../views/company/detail'),
                meta: { keepAlive: true },
            }

        ]
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router