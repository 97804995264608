<template>
  <div id="app">
    <!-- <keep-alive v-if="isLoggedIn">
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive || !isLoggedIn"></router-view> -->
     <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLoggedIn: false,
    };
  },
  metaInfo() {
    return {
      title: this.GLOBAL.sysName,
    };
  },
  watch: {
    $route() {
      // if the route changes...
      let userInfo = sessionStorage.getItem("userInfo")
      if (userInfo == null) {
        this.isLoggedIn = false;
      } else {
        this.isLoggedIn = true;
      }
    }
  }
};
</script>
<style lang="scss">
html,
body,
.el-container,
.el-aside,
.el-menu {
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  text-align: left;
  height: 100%;
}
</style>
