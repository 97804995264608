<script>
// 部署
// scp -r ./* tbs_test:/home/tbs/survey_platform_frontend
const host='https://survey-platform-test.tbs-datum.com/api'; //开发
// const host='https://survey-platform.tbs-datum.com/api'; //最新域名
// const host='https://survey-platform.tbs-datum.com/api' //生产
// const host='https://survey-test.tbs-datum.com/api' //新域名
// const host='http://192.168.50.6:8067/'; //ZLL
const sysName='问卷调查管理系统';
  export default
  {
    host, //host
    sysName,
  }
</script>

